<template>
  <div>
    <div class="d-flex justify-space-between mb-4">
      <v-btn :to="{name: 'clientSecond'}" text color="error" outlined>
        <v-icon>mdi-arrow-left</v-icon>
        <span> Back</span>
      </v-btn>
      <v-btn @click="openAddModals()" text outlined color="indigo accent-4">
        Add branch
      </v-btn>
    </div>

    <v-card class="pa-3 mt-5">
      <v-card-title>Branches</v-card-title>
      <v-card class="ma-2 pa-4">
        <!-- <v-table> -->
        <v-card-text>
          <v-data-table
              :headers="headers"
              :items="dataItem"
          >

            <template v-slot:item.actions="{item}">
              <div class="d-flex justify-center">
                <!--                <v-icon @click="reRegisterClientEpos(item.id)" class="mx-2">mdi-update</v-icon>-->
                <v-icon @click="toggleActivation(item.id)" class="cursor-pointer mr-4 pa-0" style="color: #ff9800">
                  {{ item.isDeleted ? 'mdi-toggle-switch' : 'mdi-toggle-switch-off' }}
                </v-icon>
                <!--                <v-btn @click="openPassword(item.id)" icon color="green" small>-->
                <!--                  <v-icon small> mdi-lock</v-icon>-->
                <!--                </v-btn>-->
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs}">
                    <v-icon
                        @click="reRegisterBranchEpos(item.id)"
                        class="mx-2"
                        v-bind="attrs"
                        v-on="on"
                    >mdi-update
                    </v-icon>
                  </template>
                  <span>Re-Register</span>
                </v-tooltip>
                <v-btn @click="getclientBranch(item.id)" icon color="#397dfb" small>
                  <v-icon small> mdi-pencil</v-icon>
                </v-btn>
                <!--                <v-btn icon color="warning" small @click="getclientBranch(item.id)">-->
                <!--                  <v-icon small> mdi-eye</v-icon>-->
                <!--                </v-btn>-->
              </div>
            </template>
          </v-data-table>
        </v-card-text>
        <!-- </v-table> -->
      </v-card>

    </v-card>
    <!-- modals-- -->
    <v-dialog width="800" v-model="openmodal">
      <v-card class="pa-1" outlined>
        <v-card-title v-if="update">Add Client Branch</v-card-title>
        <v-card-title v-else>edit client branch</v-card-title>
        <v-card-text>
          <v-container>
            <v-text-field v-model='branch.name' outlined label="Branch name"></v-text-field>
            <v-text-field v-model='branch.smsName' outlined label="SMS name"></v-text-field>
            <v-card v-for="(epos, index) in branch.eposes" :key="epos.id" class="mt-2 pa-3" outlined>
              <span style="float: right" @click="removeEpos(index)" class="mdi mdi-close error--text cursor-pointer">remove epos</span>
              <v-text-field v-model="epos.merchantId" outlined label="Merchant ID"></v-text-field>
              <v-text-field v-model="epos.terminalId" outlined label="Terminal ID"></v-text-field>
              <v-row>
                <v-col md="6">
                  <v-select
                      v-model="epos.terminalType"
                      :items="terminalType"
                      label="Terminal Type"
                      item-text="name"
                      item-value="id"
                      outlined
                  ></v-select>
                </v-col>
                <v-col md="6">
                  <v-select
                      v-model="epos.emitterType"
                      :items="[{id: 0, name: 'Ucard'}, {id: 1, name: 'Humo'}]"
                      label="Emitter Type"
                      item-text="name"
                      item-value="id"
                      outlined
                  ></v-select>
                </v-col>
                <v-col md="6">
                  <v-select
                      v-model="epos.type"
                      :items="eposType"
                      label="Epos Type"
                      item-text="name"
                      item-value="id"
                      outlined
                  ></v-select>
                </v-col>
                <v-col sm="6">
                  <v-select
                      v-model="epos.payPurpose"
                      :items="payPurposes"
                      label="Pay Purpose"
                      item-text="name"
                      item-value="id"
                      outlined
                  ></v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col sm="6">
                  <v-checkbox class="mr-4" v-model="epos.isWithCommission" outlined label="isWithCommission">
                  </v-checkbox>

                </v-col>
                <v-col sm="6">
                  <v-text-field label="commission" suffix="%" outlined v-model="epos.commission" :disabled="!epos.isWithCommission">
                  </v-text-field>
                </v-col>
              </v-row>
            </v-card>
            <v-btn @click="addEpos()" text color="primary" class="align-center">
              <span class="mdi mdi-plus">add epos</span>
            </v-btn>
            <v-row>
              <v-col>
                <v-btn style="width: 100%" color="error" @click="openmodal = false">cancel</v-btn>
              </v-col>
              <v-col>
                <v-btn v-if="update" @click="addnewBranch()" style="width: 100%" color="success">create</v-btn>
                <v-btn v-else color="" @click="editBranch(branch.id)" style="width: 100%">edit</v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- modals -->
  </div>
</template>

<script>
export default {
  name: 'ClientsView',
  beforeRouteEnter(to, from, next) {
    if ('id' in to.params) {
      next()
    } else {
      next({name: 'ClientSecond'})
    }
  },
  mounted() {
    this.Id = this.$route.params.id
  },
  data() {
    return {
      headers: [
        {text: 'ID', value: 'id', align: 'center', sortable: false},
        {text: 'Name', value: 'name', align: 'center', sortable: false},
        {text: 'SMS Name', value: 'smsName', align: 'center', sortable: false},
        {text: 'isDeleted', value: 'isDeleted', align: 'center', sortable: false},
        {text: 'actions', value: 'actions', align: 'center', sortable: false}
      ],
      openmodal: false,
      update: true,
      Group: [],
      branch: {
        clientId: '',
        id: null,
        name: '',
        smsName: '',
        eposes: [{
          merchantId: '',
          terminalId: '',
          emitterType: 0,
          type: 0,
          terminalType: 0,
          payPurpose: 0,
          status: 0,
          commission: 0.0,
          isWithCommission: 0,
        }],
      },
      dataItem: [],
      terminalType: [
        {id: 1, name: "Debit"},
        {id: 2, name: "Credit"},
        {id: 3, name: "P2P"},
      ],
      eposType: [
        {id: 0, name: "Main"},
        {id: 1, name: "Commission"},
        {id: 2, name: "AutoPay"},
        {id: 3, name: "Credit"},
      ],
      payPurposes: [
        {id: 0, name: "Payment"},
        {id: 1, name: "Loan"},
        {id: 2, name: "EWallet"},
        {id: 3, name: "Exchange"},
        {id: 4, name: "Deposit"},
        {id: 5, name: "QrGci"},
        {id: 6, name: "P2PExternal"}
      ]
    }
  },
  methods: {
    openAddModals() {
      this.emptyBranch()
      this.openmodal = true
      this.update = true
    },
    emptyBranch() {
      this.branch.clientId = this.$route.params.id,
          this.branch.name = '',
          this.branch.smsName = '',
          this.branch.eposes = []
    },
    addnewBranch() {
      this.createBranch()
      this.openmodal = false
    },
    addEpos() {
      this.branch.eposes.push({
        merchantId: '',
        terminalId: '',
        emitterType: 0,
        type: 0,
        terminalType: 0,
        payPurpose: 0,
        status: 0,
        commission: 0.0,
        isWithCommission: 0,
      })
    },
    removeEpos(index) {
      this.branch.eposes.splice(index, 1)
    },
    async createBranch() {
      try {
        const res = await this.$http.post(`Client/addClientBranch`, this.branch);
        if (!res.error) {
          console.log(res.result);
          this.successNotification();
        }
      } catch (e) {
        this.errorNotification(e.response.data.message)
      }
    },
    async editBranch(id) {
      try {
        const res = await this.$http.post(`Client/editClientBranch`, this.branch);
        if (!res.error) {
          console.log(res.result);
          this.successNotification();
        }
      } catch (e) {
        this.errorNotification(e.response.data.message)
      }
    },
    async getclientBranch(id, branchId) {
      this.openmodal = true
      this.update = false
      try {
        const res = await this.$http.get(`Client/getClientBranch?clientId=${this.$route.params.id}&branchId=${id}`).then((res) => {
          if (!res.error) {
            console.log(res.result);
            this.branch = {...res.result}
            this.branch.smsName = res.result.smsName ?? '';
            // this.successNotification();
          }
        });
      } catch (e) {
        this.errorNotification(e.response.data.message)
      }
    },

    async reRegisterBranchEpos(id) {
      try {
        const res = await this.$http.get(`Client/reRegisterClientBranchEpos?branchId=${id}`)
        if (!res.error) {
          this.successNotification();
          console.log(res.result);
        }
      } catch (e) {
        this.errorNotification(e.response.data.message)
      }
    },

    async toggleActivation(id) {
      try {
        const res = await this.$http.get(`Client/toggleClientBranchActivation?clientId=${this.$route.params.id}&branchId=${id}`).then((res) => {
          if (!res.error) {
            // console.log(res.result);
            this.branch = res.result;
          }
        })
      } catch (e) {
        this.errorNotification(e.response.data.message)
      }
    },

    async getAccess() {
      await this.$http.get(`Client/getAccesses`).then((res) => {
        // console.log(res.result.groups)
        this.Group = res.result.groups
      })
    }
  },


  created() {
    let id = this.$route.params.id
    this.$http.get(`Client/getClientBranches?clientId=${id}`).then((res) => {
      if (res && res.result) {
        // console.log(res.result.branches);
        this.dataItem = res.result.items
      } else {
        this.$router.push({name: 'ClientSecond'})
      }
    }),
        this.getAccess()


    // this.getclientBranch()
  },
}
</script>